import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';

import { AlertDialogComponent } from '../../shared/components/alert-dialog/alert-dialog.component';

import { GlobalServices } from '../../shared/services/global.services';

import { HeaderModel } from '../../shared/models/shared.model';
import { isEmptyObject } from '../../shared/functions/helpers';
import { OrderService } from '../../shared/services/order.service';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
    @Input() headerData: HeaderModel;

    @ViewChild('notificationCounter') notificationCounter: ElementRef;

    public isOrdersPage = false;
    public isMenuOpen = false;
    constructor(
        public globalService: GlobalServices,
        public orderService: OrderService,
        public router: Router,
        private dialog: MatDialog,
        private restfullServices:RestfullServices
    ) {
        this.isOrdersPage = router.url.includes('add-order') && globalService.isMobile$.getValue();

        router.events
            .pipe(filter(route => route instanceof NavigationEnd))
            .subscribe((resp: any) => {
                this.isOrdersPage = resp.url.includes('add-order');
                if (!this.isOrdersPage) {
                    this.globalService.headerClickAction$.next(null);
                }
            });
    }
    ngOnInit(){
       // let CheckHotelStatus=setInterval(()=>{
            this.restfullServices.getHotelStatus().subscribe((data)=>{
                if(data.status=="inactive"){
                    this.globalService.logOut();
                    //clearInterval(CheckHotelStatus);
                }
            })
      //  },300000)
    }
    ngAfterViewInit() {
        this.orderService.orderCount$
            .subscribe(num => this.notificationCounter.nativeElement.style = `padding: 4px ${num > 9 ? '5px' : '7px'}`);
            //console.log(this.headerData)
    }

    public navigateDashboard() {
        if (this.headerData.confirmBack) {
            this.confirmNavigate();
        } else {
            if (this.headerData.navigationPage) {
                if (!isEmptyObject(this.headerData.queryParams)) {
                    this.router.navigate([this.headerData.navigationPage], {
                        queryParams: this.headerData.queryParams
                    }).then(() => this.headerData.queryParams = {});
                } else {
                    this.router.navigate([this.headerData.navigationPage]);
                }
            } else {
                this.globalService.headerBackAction$.next(true);
            }
            this.headerData.navigationPage = null;
        }
    }

    public routeToLogin() {
        this.globalService.logOut();
    }

    public showSidebar() {
        this.globalService.sidebarState$.next(!this.globalService.sidebarState$.getValue());
    }

    public onCartClick() {
        this.globalService.headerClickAction$.next(true);
    }

    private confirmNavigate() {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'custom_dialog_container',
            width: '440px',
            data: {
                title: this.headerData.backActionTitle,
                message: this.headerData.backActionMsg,
                type: 'confirmUnsavedChanges'
            }
        });
        dialogRef.afterClosed()
            .pipe(filter(item => !!item))
            .subscribe(() => {
                this.headerData.confirmBack = false;
                this.navigateDashboard();
                this.orderService.clearOrderConfig();
            });
    }
    public menuOpened() {
        this.isMenuOpen = !this.isMenuOpen;
    }
}
