import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GuestModelSpa, SelectOptionsModel, WakeUpCallDialogModel } from 'src/app/shared/models/shared.model';
import { ViewRequestComponent } from '../../housekeeping/view-request/view-request.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as introJs from 'intro.js';
import { ViewWakeUpCallComponent } from "../view-wake-up-call/view-wake-up-call.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-add-wakeup-call',
  templateUrl: './add-wakeup-call.component.html',
  styleUrls: ['./add-wakeup-call.component.scss']
})
export class AddWakeupCallComponent implements OnInit, AfterViewInit {
  introJS = introJs.default();
  public requestStatus: number | string;
  public isEdit: boolean = false;
  requestDetails: any;
  userGroup: GuestModelSpa[] = [];
  filteredUser: Observable<string[] | GuestModelSpa[]>;
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

  public selectOptions: SelectOptionsModel[] = [
    {
      label: 'Upcoming',
      value: 1
    },
    {
      label: 'Completed',
      value: 2
    },
    {
      label: 'Missed',
      value: 3
    },
    {
      label: 'Failed',
      value: 4
    },
    {
      label: 'Cancel',
      value: 5
    }

  ];

  public wakeupCallForm = new UntypedFormGroup({
    date: new UntypedFormControl('', [Validators.required]),
    time: new UntypedFormControl('', [Validators.required]),
    guestName: new UntypedFormControl('', [Validators.required]),
    room: new UntypedFormControl(''),
    note: new UntypedFormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<AddWakeupCallComponent>, private globalService: GlobalServices, private restfullServices: RestfullServices,
    private datePipe: DatePipe, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any,
     private dialog: MatDialog
  ) {
  }


  ngOnInit(): void {
    if (this.data != undefined || this.data != null) {
      this.isEdit = this.data.isEdit;
      this.requestDetails = this.data.requestDetails;
     
      if (this.data.isEdit == true) {
        this.wakeupCallForm.controls['date'].setValue(new Date(this.data.requestDetails.date));
        let newTime="";       
        if(this.data.requestDetails.time.toString().includes("Tomorrow") ||
        this.data.requestDetails.time.toString().includes("Today"))
        {
          newTime= this.data.requestDetails.time.toString().split(',')[1];
          this.wakeupCallForm.get('time').setValue(this.convertTimeTo24(newTime.trim()));
        }
        else
        {
          this.wakeupCallForm.get('time').setValue(this.convertTimeTo24(this.data.requestDetails.time));
        }
        //this.wakeupCallForm.get('time').setValue(this.convertTimeTo24(this.data.requestDetails.time));
        this.wakeupCallForm.controls['guestName'].setValue(this.data.requestDetails.guestName);
        this.wakeupCallForm.get('note').setValue(this.data.requestDetails.note);
        this.requestStatus = this.selectOptions.find(x => x.label == this.data.requestDetails.status).value || this.selectOptions[0].label;
      }
    }
    this.restfullServices.getGuestListWithId()
      .subscribe(
        response => {
          if (response) {
            this.userGroup = response;
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));
    this.filteredUser = this.wakeupCallForm.controls['guestName'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.guestName;
        this.wakeupCallForm.controls['room'].setValue(value.room);
        return name ? this._filter(name as string) : this.userGroup.slice();
      }),
    );
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 22
          tooltipClass: "intro-step-22",
          title: "CREATE WAKE-UP CALL",
          element: document.getElementById('create_wakeup_call'),
          intro: "Well done! Here, you can personalize your guest’s wakeup call experience. Enter all the essential details for the upcoming wakeup call, specifying the desired time and any special preferences.",
          position: 'right'
        },

      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: 'tooltip',
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });
    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener('click', this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener('click', this.closeStep.bind(this));
      }
    }, 100);

  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage('isTourRunning');
    window.location.href = '#/pages/wake-up-call';
  }

  closeStep(): void {
    window.location.href = '#/pages/wake-up-call';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
        const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
        const guidedTourAddWakeUpFlag = this.restfullServices.getLocalStorage('guidedTourAddWakeUpFlag');
        if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourAddWakeUpFlag == 'fasle') {
          this.restfullServices.setLocalStorage('guidedTourAddWakeUpFlag', 'true');
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }

  displayFn(guest: GuestModelSpa): string {
    return guest && guest.guestName ? guest.guestName : '';
  }

  private _filter(value: string): GuestModelSpa[] {
    const filterValue = value.toLowerCase();
    return this.userGroup.filter(option => option.guestName.toLowerCase().includes(filterValue));
  }

  onSearchChange(searchValue: string): void {
    //console.log(searchValue);
  }

  onNoClick(): void {   
    if(!this.isEdit)
    {
      this.dialogRef.close();
    }else
    {
      this.dialogRef.close();
      this.dialog.open(ViewWakeUpCallComponent, {
        panelClass: ['share-link_container', 'border-top'],
        width: '490px',
//height:'400px',
        autoFocus: true,
        data: {
          title: 'NEW REQUEST!',
          requestDetails: this.data.requestDetails,
        }
      })
      .afterClosed()
        .subscribe((resp) => {
          this.globalService.headerClickAction$.next(null);
          if (resp) {
            
          }
          this.dialogRef.close();
        });
    }
    
  }

  saveWakeUp() {
    if (!this.wakeupCallForm.get('date').value) {
      this.wakeupCallForm.get('date').markAllAsTouched();
    }
    if (!this.wakeupCallForm.get('time').value) {
      this.wakeupCallForm.get('time').markAllAsTouched();
    }
    if (!this.wakeupCallForm.get('guestName').value) {
      this.wakeupCallForm.get('guestName').markAllAsTouched();
    }
    if (this.wakeupCallForm.valid) {
      let current_datetime = this.datePipe.transform(this.wakeupCallForm.controls['date'].value, "MM/dd/yyyy");
      var payload = {
        guestId: this.isEdit == true ? 0 : this.wakeupCallForm.controls['guestName'].value.id,
        wakeUpCalldate: current_datetime,
        wakeUpCallTime: this.convertTime(this.wakeupCallForm.controls['time'].value),
        dateTime: new Date(),
        note: this.wakeupCallForm.controls['note'].value,
        isEdit: this.isEdit == true ? 1 : 0,
        status: this.isEdit == true ? this.selectOptions.find(x => x.value == this.requestStatus).label : "Upcoming",
        wakeupCallId: this.isEdit == true ? +this.requestDetails.id : 0,
      }
      this.restfullServices.addWakeUpCall(payload)
        .subscribe(
          response => {
            if (response) {
              this.dialogRef.close({ data: response })
            }
          },
          () => this.globalService.showAlert(this.globalService.errorMsg));
    }
  }

  convertTime(input: string) {
    let time = input.split(":");
    let hour = time[0];
    let min = time[1];
    let suffix = "";
    if (+hour > 12) {
      hour = (+hour - 12).toString();
      suffix = "pm";
    }
    else
      suffix = "am";
    //hour = +hour<10?"0"+hour:hour;
    return hour + ":" + min + " " + suffix;
  }
  convertTimeTo24(input: string) {
    let hours = Number(input.match(/^(\d+)/)[1]);
    let minutes = Number(input.match(/:(\d+)/)[1]);
    const AMPM = input.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() === "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() === "am" && hours == 12) hours = hours - 12;

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return `${sHours}:${sMinutes}`;
  }

}
