import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    ViewRequestDialogModel,
    HouseKeepingCategoryModel,
    SelectOptionsModel
} from 'src/app/shared/models/shared.model';

@Component({
    selector: 'app-view-request',
    templateUrl: './view-request.component.html',
    styleUrls: ['./view-request.component.scss']
})
export class ViewRequestComponent implements OnInit {

    public requestData: HouseKeepingCategoryModel[] = [];
    public requestStatus: number | string;
    public roomNumber: string;
    public requestNote: string = null;
    public requestTotal = 0;
    public taskRefNumber:number;

    public selectOptions: SelectOptionsModel[] = [
        {
            label: 'Open',
            value: 1
        },
        {
            label: 'Delivered',
            value: 2
        }
    ];

    constructor(
        public dialogRef: MatDialogRef<ViewRequestComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ViewRequestDialogModel
    ) {
    }

    ngOnInit(): void {
        this.data.houseKeepingCategory.map(data => {
            this.requestData.push({
                id: data.id,
                name: data.name,
                houseKeepingRequest: this.data.requestDetails.filter(details => details.requestCategory === data.id)
            });
        });
        this.data.requestDetails.map(details => {
            if (details.requestCategory === 0 && details.requestNote) {
                this.requestNote = atob(details.requestNote);
            }
        });
        this.requestData.map(data => this.requestTotal += data.houseKeepingRequest.length);
        this.requestStatus = this.data.requestDetails[0]?.requestStatus || this.selectOptions[0].value;
        this.taskRefNumber = this.data.requestDetails[0]?.id || 0;
        this.roomNumber = this.data.requestDetails[0]?.roomNumber;
        //console.log("housekeeping data",this.data)
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
