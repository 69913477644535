<div class="order-details" fxLayout="column" fxFlex="100" fxLayoutAlign="start start">
    <div>
        <span class="breadcrumb" (click)="navigateToOrders()">All Orders</span><span class="breadcrumbdark"> / {{orderNumber}}</span>
    </div>
    <div class="order-details__header width_100" fxLayoutAlign="space-between center">      
        <div class="order-details__header-status"
            [class.order-details__header-status-unpaid]="order?.payment === 'unpaid'">
            {{capitalizeFirstLetter(order?.payment)}}
        </div>
        <div class="order-details__header-download" fxLayoutAlign="center center">
            <!-- <mat-icon [svgIcon]="'download-outline'"></mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" style="margin-right: 5px;">
                <path id="download-outline" d="M16.071,6.382v5.647h1.254L15,14.072l-2.325-2.042h1.254V6.382h2.143M18.214,4.5H11.786v5.647H7.5L15,16.735l7.5-6.588H18.214V4.5M22.5,18.618H7.5V20.5h15Z" transform="translate(-7.5 -4.5)" fill="#868E96"/>
              </svg>
            Download invoice
        </div>
    </div>
    <form [formGroup]="orderDetailsForm" *ngIf="orderDetailsForm" class="order-details__main" fxLayout="column"
        fxLayoutGap="20px" fxLayoutAlign="center start">
        <div class="order-details__main-edit cursor_pointer" fxLayoutAlign="start center"
            [class.order-details__main-edit-disabled]="order?.status === 'cancelled' || order?.status === 'delivered'"
            [class.desktop]="!globalService.isMobile" (click)="editOrder()">
            Edit Order
        </div>

        <div class="order-details__main-info width_100" fxLayout="column">
            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Order Status</div>

                <div class="order-details__main-info-select active" fxLayout="row" fxLayoutAlign="space-between center"
                    [class.disabled-color]="order?.status === 'cancelled' || order?.status === 'delivered'"
                    [matMenuTriggerFor]="menu" #t="matMenuTrigger" [ngClass]="order?.status">
                    <div
                        *ngIf="order?.status !='outfordelivery' && order?.status !='readyforpickup' && order?.status !='pickedup'">
                        {{order?.status}}</div>
                    <div *ngIf="order?.status ==='outfordelivery'">Out for delivery</div>
                    <div *ngIf="order?.status ==='readyforpickup'">Ready for pick-up</div>
                    <div *ngIf="order?.status ==='pickedup'">Picked-up</div>
                    <mat-icon [class.rotated]="t.menuOpen" [svgIcon]="'arrow-down'"></mat-icon>
                </div>

                <mat-menu #menu="matMenu" class="order-details-status" yPosition="below">
                    <div class="menu-option" (click)="changeStatus(item)"
                        *ngFor="let item of statusOptions; let i = index">
                        <div class="order-details__main-info-select" fxLayout="row" fxLayoutAlign="space-between center"
                            [ngClass]="item.value">
                            <div>{{item.label}}</div>
                        </div>
                    </div>
                </mat-menu>
            </div>

            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Order No.</div>

                <div class="order-details__main-info-value">{{order?.orderNumber}}</div>
            </div>

            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Date Placed</div>

                <div class="order-details__main-info-value">{{newDate(order?.datePlaced)}}</div>
            </div>

            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" style="color: #D9480F;" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Amount Due</div>

                <div class="order-details__main-info-value" style="color: #D9480F;">{{(order?.payment == "unpaid"?totalAmount:"0.00")|number:'1.2-2'}} {{globalService.currency}}</div>
            </div>
            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Amount Paid</div>

                <div class="order-details__main-info-value">{{(order?.payment == "paid"?totalAmount:"0.00")|number:'1.2-2'}} {{globalService.currency}}</div>
            </div>

            <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                    Delivery Type</div>

                <div class="order-details__main-info-value">{{getDeliverType(order?.delivery)}}</div>
            </div>
        </div>

        <div class="order-details__main-order width_100" fxLayout="column" fxLayoutAlign="center start">
            <div class="order-details__main-order-header width_100" fxLayoutAlign="start center">
                <div class="order-details__main-order-item title width_100">ITEM</div>
                <div class="order-details__main-order-price title width_100">PRICE</div>
                <div class="order-details__main-order-qty title width_100">QTY</div>
                <div class="order-details__main-order-total title width_100">TOTAL</div>
            </div>
            <div class="order-details__main-order-orders width_100" fxLayoutAlign="start center"
                *ngFor="let order of order?.orders">
                <div class="order-details__main-order-item width_100">{{order.order.title}}</div>

                <!-- <div class="order-details__main-order-qty width_100">{{order.amount}}</div> -->
                <div class="order-details__main-order-price width_100">{{order.order.discountedPrice ? (order.order.discountedPrice| number:'1.2-2'):  (order.order.price | number:'1.2-2')}}
                    {{order.order.discountedPrice?globalService.currency:''}}</div>
                <div class="order-details__main-order-qty width_100">{{order.amount}}</div>
                <div class="order-details__main-order-total width_100">
                    {{getTotalAmount(order.order.discountedPrice,order.order.price,order.amount) | number:'1.2-2'}} {{globalService.currency}}
                </div>
            </div>
            <div class="order-details__main-order-result pt-20 width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                    {{subTotal | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Subtotal</div>
            </div>
            <div class="order-details__main-order-result width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                    {{tax | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Tax (10%)</div>
            </div>
            <div class="order-details__main-order-result width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                    {{0.00 | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Voucher</div>
            </div>
            <div class="order-details__main-order-result total width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                    {{totalAmount | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty width_100">Total</div>
            </div>
        </div>

        <div class="order-details__main-note width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
            <mat-label>Note/Remarks</mat-label>
            <mat-form-field class="width_100 disabled-color" appearance="outline">
                <textarea matInput placeholder="No note/remarks" formControlName="note"></textarea>
            </mat-form-field>
        </div>
    </form>
</div>