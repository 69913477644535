import { Component, OnInit, ElementRef, ViewChild, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';

import firebase from 'firebase/compat/app';

import { filter, take, takeUntil } from 'rxjs/operators';

import { AlertDialogComponent } from '../../../shared/components/alert-dialog/alert-dialog.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { RestfullServices } from '../../../shared/services/restfull.services';

import { ChatGuestListModel, GlobalOrderModel, GuestDetailModel, HeaderModel } from '../../../shared/models/shared.model';
import { getTotalNumber, isEmptyObject, snapshotToArray } from '../../../shared/functions/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ShareLinkComponent } from '../share-link/share-link.component';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { error } from 'console';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { CheckoutAddPaymentComponent } from '../checkout-add-payment/checkout-add-payment.component';
import { CheckOutPopupComponent } from '../check-out-popup/check-out-popup.component';
import { CheckInPopupComponent } from '../check-in-popup/check-in-popup.component';

@Component({
    selector: 'app-guest-detail',
    templateUrl: './guest-detail.component.html',
    styleUrls: ['./guest-detail.component.scss']
})
@AutoUnsubscribe()
export class GuestDetailComponent implements OnInit, OnDestroy {

    @ViewChild('chatcontent') chatcontent: ElementRef;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    public guestDetail: GuestDetailModel;
    public secondaryGuestDetail = new MatTableDataSource([]);
    public chats = [];
    public guestDataSource = new MatTableDataSource([]);
    public dataFile = "";
    public dataFile1 = "";
    public dataFile2 = "";
    public pdfSize = "";
    public size1 = "";
    public size2 = "";
    public id1Available: boolean = false;
    public id2Available: boolean = false;
    isSecondary: boolean = false;
    isUserRegistered: boolean = false;
    hotelRooms: any
    public checkOutData: {};
    public checkInData: {};

    private canAddGuest = true;

    data = {
        roomname: '',
        message: '',
        type: '',
        guestname: '',
        key: '',
        messageCount: 0,
        chatStatus: ''

    };

    public roomDetails: any = {};

    public chatStatus;
    public selectOptions = [
        {
            label: 'Open',
            value: 'open'
        },
        {
            label: 'Close',
            value: 'close'
        }
    ];

    public orders: {};
    public paymentDetails: {
        amountDue: 0,
        subTotal: 0,
        total: 0,
        amountPaid: 0
    };
    public addPayment: {};
    public orderDetailsForm = new UntypedFormGroup({
        orderStatus: new UntypedFormControl(),
        note: new UntypedFormControl()
    });
    public guestList: ChatGuestListModel[];

    public selectedTab = 0;

    public selectedIndex: number = 0;

    private ref = firebase.database().ref('chatrooms/');

    get isMobileDisplay() {
        return !this.deviceDetectorService.isDesktop();
    }

    constructor(
        public globalService: GlobalServices,
        private dialog: MatDialog,
        private datepipe: DatePipe,
        private ngZone: NgZone,
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private restfullServices: RestfullServices,
        private deviceDetectorService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        private snackBar: MatSnackBar
    ) {
        this.data.type = 'message';
    }

    ngOnInit() {
        this.globalService.selectedGuestId = this.activatedRoute.snapshot.queryParams.id;
        if (this.activatedRoute.snapshot.queryParams.shareLink === "true") {
            this.selectedIndex = 1;
        }
        this.data.roomname = this.globalService.HOTELID + '_' + this.globalService.selectedGuestId;
        this.data.guestname = this.globalService.selectedGuestName;

        const queryParams: any = {
            id: this.globalService.selectedGuestId,
        };
        this.route.navigate(['/pages/guests/guest'], { queryParams })

        this.globalService.headerData$.next({
            headerTitle: 'Guest List',
            showBackButton: true,
            info1: '',
            info2: '',
            navigationPage: '/pages/guests'
        });
        this.getGuestDetails();
        this.getInvoiceDetails();
        this.restfullServices.downloadPdf(this.globalService.selectedGuestId).pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                if (response.url) {
                    this.dataFile = response.url;
                    this.pdfSize = response.size;
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
        this.getIdProof();
        this.getAllRoomDetails()
    }

    ngOnDestroy() { }

    public openShareLink() {
        this.dialog.open(ShareLinkComponent, {
            panelClass: ['share-link_container', 'border-top'],
            width: '440px',
            data: {
                guests: [
                    // {
                    //     names: this.guestDetail.lastName + ', ' + this.guestDetail.firstName,
                    //     email: this.guestDetail.email,
                    //     adults: this.guestDetail.adults
                    // },
                    ...this.guestDetail.guests.map(guest => ({
                        names: guest.lastName + ', ' + guest.firstName,
                        email: guest.email,
                        adults: this.guestDetail.adults,
                        userId: guest.userId,
                        appActivity: guest.appActivity
                    }))
                ],
                type: 'confirmDelete'
            }
        }).afterClosed().pipe(filter(resp => !resp))
            .pipe(takeUntil(this['destroy$']))
            .subscribe();
        //() => this.route.navigate(['/pages/guests'])
    }
    getTotalNumber(value: number) {
        return getTotalNumber(value);
    }
    public editGuest() {
        const queryParams: { id: number, key?: string } = {
            id: this.globalService.selectedGuestId,
        };
        if (this.data.key.length) {
            queryParams.key = this.data.key;
        }
        this.route.navigate(['/pages/guests/edit-guest'], {
            queryParams
        });
    }
    public getAge(event: any) {
        var timeDiff = Math.abs(Date.now() - event);
        var age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        return age
    }

    public confirmDeleteGuest() {
        const str = 'Do you really want to delete this Guest? This action cannot be undone.';
        const alertTitle = 'Delete ' + this.getHeaderData().info1 + '?';
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'custom_dialog_container',
            width: '440px',
            data: {
                title: alertTitle,
                message: str,
                type: 'confirmDelete'
            }
        });
        dialogRef.afterClosed()
            .pipe(filter(result => !!result), takeUntil(this['destroy$']))
            .subscribe(() => this.deleteGuest());
    }

    public getHeaderData(): HeaderModel {
        return this.globalService.headerData$.getValue();
    }

    private deleteGuest() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.deleteGuest(this.guestDetail.id)
            .pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                if (response) {
                    this.canAddGuest = false;
                    firebase.database().ref('chatrooms/' + this.data.key).remove();
                    this.globalService.showLoader$.next(false);
                    this.route.navigate(['/pages/guests']);
                } else {
                    this.globalService.showAlert(this.globalService.errorMsg);
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }

    private getChatGuestList() {
        this.guestList = [];
        this.globalService.showLoader$.next(true);
        this.restfullServices.getChatGuestList()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                response.forEach(element => {
                    element.checkInDate = new Date(element.checkInDate);
                    element.checkOutDate = new Date(element.checkOutDate);
                });
                this.guestList = response;
                this.changeDetectorRef.detectChanges();
                this.globalService.showLoader$.next(false);
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }

    private triggerResize() {
        this.ngZone.onStable.pipe(take(1))
            .pipe(takeUntil(this['destroy$']))
            .subscribe(() => this.autosize.resizeToFitContent(true));
    }

    private getRoomDetails() {
        this.globalService.showLoader$.next(true);
        this.ref.on('value', resp => {
            this.roomDetails = snapshotToArray(resp).find(item => item.roomname === this.globalService.HOTELID + '_' + this.guestDetail.id);
            if (isEmptyObject(this.roomDetails)) {
                this.addRoom();
                this.globalService.showLoader$.next(false);
            } else {
                // mark only admin messages as 0 since chat page is open
                const newAdminMessage = firebase.database().ref('chatrooms/' + this.data.key + '/unreadAdminMessages').set(0);
                this.fetchChat();
                this.data.key = this.roomDetails.key;
                this.data.messageCount = Number(this.roomDetails.unreadGuestMessages);
                this.globalService.showLoader$.next(false);
            }
            this.getChatGuestList();
        });
    }

    private fetchChat() {
        firebase.database().ref('chatrooms/' + this.roomDetails.key + '/chats').on('value', resp => {
            this.chats = [];
            this.chats = this.globalService.bindDate(snapshotToArray(resp));
        });
    }

    private addRoom() {
        if (this.data.guestname && this.canAddGuest) {
            const newRoom = this.ref.push();
            newRoom.set({
                roomname: this.data.roomname,
                guestname: this.data.guestname,
                status: 'open',
                unreadAdminMessages: 0,
                unreadGuestMessages: 0
            });
        }
    }

    private sendMessage() {
        if (this.data.message && this.data.message.length > 0) {
            const newMessage = firebase.database().ref('chatrooms/' + this.roomDetails.key + '/chats').push();
            newMessage.set({
                message: this.data.message,
                // roomname: this.data.roomname,
                date: this.datepipe.transform(new Date(), 'dd-MM-yyyy'),
                time: this.datepipe.transform(new Date(), 'HH:mm:ss'),
                type: this.data.type,
                user: this.globalService.HOTELID
            });
            this.data.messageCount++;
            firebase.database().ref('chatrooms/' + this.data.key + '/unreadGuestMessages').set(this.data.messageCount);
            this.data.message = null;
        }
    }

    confirmDisableGuest(element) {
        const str = 'Are you sure you want to disable this user? you can always reactivate this user at a later point if needed.';
        const alertTitle = 'Disable user?';
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'custom_dialog_container',
            width: '440px',
            data: {
                title: alertTitle,
                message: str,
                type: 'confirmDisable'
            }
        });
        dialogRef.afterClosed()
            .pipe(filter(result => !!result), takeUntil(this['destroy$']))
            .subscribe(() => this.disableGuest(element));
    }

    disableGuest(element) {
        element.appActivity = "disabled"
        this.restfullServices.setAppActivity(element.userId, element.appActivity).subscribe(async resp => {
            if (resp) {
                this.globalService.showLoader$.next(true);
                this.restfullServices.getSecondaryGuestDetails(this.globalService.selectedGuestId).subscribe(async resp => {
                    this.secondaryGuestDetail.data = await resp.secondaryGuests;
                    this.isSecondary = true;
                    this.guestDetail.guests = await resp.guests;
                    this.guestDataSource.data = await resp.guests;
                    // console.log(this.guestDataSource.data);
                    this.globalService.showLoader$.next(false);
                }), () => this.globalService.showAlert(this.globalService.errorMsg);
            }
        }), () => this.globalService.showAlert(this.globalService.errorMsg);
    }

    private async getSecondaryGuest() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getSecondaryGuestDetails(this.globalService.selectedGuestId).subscribe(async resp => {
            this.secondaryGuestDetail.data = await resp.secondaryGuests;
            this.isSecondary = true;
            this.guestDetail.guests = await resp.guests;
            this.guestDataSource.data = await resp.guests;
            // console.log(this.guestDataSource.data);
            this.globalService.showLoader$.next(false);
        }), () => this.globalService.showAlert(this.globalService.errorMsg);
    }
    private getGuestDetails() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getGuestDetails(this.globalService.selectedGuestId)
            .pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                this.guestDetail = response;
                const dateStr = this.guestDetail?.dob;
                const parts = dateStr.split('/');
                const date = new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
                this.guestDetail.age = this.getAge(date).toString()
                this.getSecondaryGuest();
                const headerData = this.getHeaderData();
                if (!this.globalService.isMobile) {
                    headerData.info1 = this.guestDetail.lastName + ', ' + this.guestDetail.firstName;
                    headerData.info2 = 'Booking ID: ' + this.guestDetail.bookingNumber;
                    headerData.headerTitle = '';
                }

                this.globalService.headerData$.next(headerData);
                //this.getRoomDetails();
                this.globalService.showLoader$.next(false);
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }
    public downloadPdf() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.downloadPdf(this.globalService.selectedGuestId).pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                if (response.url) {
                    this.dataFile = response.url;
                    this.pdfSize = response.size;
                    var link = document.createElement('a');
                    link.href = this.dataFile;
                    link.download = "RegistrationForm.pdf";
                    this.globalService.showLoader$.next(false);
                    link.click();
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }
    // public downloadIdProof(imageId: number) {
    //     this.restfullServices.downloadIdProof(this.globalService.selectedGuestId, imageId).pipe(takeUntil(this['destroy$']))
    //         .subscribe(response => {
    //             if (response.url) {
    //                 //this.dataFile = response.url;
    //                 var link = document.createElement('a');
    //                 //link.href = this.dataFile;
    //                 link.download = "Id" + imageId + ".png";
    //                 link.click();
    //             }      
    //         }, () => this.globalService.showAlert(this.globalService.errorMsg));
    // }
    public downloadIdProof(imageId: number) {
        if (imageId == 1) {
            var link = document.createElement('a');
            link.href = this.dataFile1;
            link.download = "Id" + imageId + ".png";
            link.click();
        }
        else if (imageId == 2) {
            var link = document.createElement('a');
            link.href = this.dataFile2;
            link.download = "Id" + imageId + ".png";
            link.click();
        }
    }

    public getIdProof() {
        this.restfullServices.getIdProof(this.globalService.selectedGuestId).pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                if (response.url1 != "" && response.size1 != "0KB") {
                    this.id1Available = true;
                    this.dataFile1 = response.url1;
                    this.size1 = response.size1;
                }
                if (response.url2 != "" && response.size2 != "0KB") {
                    this.id2Available = true;
                    this.dataFile2 = response.url2;
                    this.size2 = response.size2;
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }
    public getInvoiceDetails() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getInvoiceDetails(this.globalService.selectedGuestId)
            .pipe(takeUntil(this['destroy$']))
            .subscribe((response) => {
                if (response) {
                    this.orders = response.orders.filter(x => x != null);
                    this.paymentDetails = response.paymentDetails;
                    this.globalService.showLoader$.next(false);
                }
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }
    public openCheckoutForm() {
        this.checkOutData = {
            id: this.activatedRoute.snapshot.queryParams.id,
            bookingNumber: this.guestDetail.bookingNumber,
            primaryGuest: this.guestDetail.lastName + ', ' + this.guestDetail.firstName,
            checkInDate: this.guestDetail.checkInDate,
            checkOutDate: this.guestDetail.checkOutDate,
            room: this.guestDetail.roomType,
            amountBalance: this.paymentDetails['amountDue'],
            email: this.guestDetail.email,
            sopagoMinihotelInfo: this.guestDetail.sopagoMinihotelInfo,
            miniHotelReservationId: this.guestDetail.miniHotelReservationId,
            miniHotelMemberSerial: this.guestDetail.miniHotelMemberSerial
        }
        this.dialog.open(CheckOutPopupComponent, {
            panelClass: ['share-link_container', 'border-top'],
            width: '440px',
            autoFocus: false,
            data: {
                data: this.checkOutData
            }
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((resp) => {
                this.globalService.headerClickAction$.next(null);
            });
    }
    public openCheckinForm() {
        //Dialog
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'custom_dialog_container',
            width: '440px',
            data: {
                title: "Complete Registration Form",
                message: "Please fill out all required information to continue the check-in process.",
                type: 'completeRegistrationForm'
            }
        });
        dialogRef.afterClosed()
            .pipe(filter(result => !!result), takeUntil(this['destroy$']))
            .subscribe(() => this.openRegistrationForm());
    }
    public getAllRoomDetails() {
        this.restfullServices.getHotelRooms().pipe(takeUntil(this['destroy$'])).subscribe(rooms => {
            if (rooms) {
                this.hotelRooms = rooms
            }
        });
    }
    getRoomType(id: number) {
        let roomType = this.hotelRooms.find(room => room.id == id)
        return roomType?.label || ''
    }

    openRegistrationForm() {
        this.restfullServices.getRegisteredUser(this.globalService.selectedGuestId).pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                if (response) {
                    this.isUserRegistered = response.isRegisteredUser;
                    if (!this.isUserRegistered) {
                        const queryParams: { id: number, key?: string } = {
                            id: this.globalService.selectedGuestId,
                        };
                        if (this.data.key.length) {
                            queryParams.key = this.data.key;
                        }
                        this.route.navigate(['/pages/guests/user-registration'], {
                            queryParams
                        });
                    }
                    else {
                        this.openCheckInPopup();
                    }
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }

    openCheckInPopup() {
        this.checkInData = {
            id: this.activatedRoute.snapshot.queryParams.id,
            bookingNumber: this.guestDetail.bookingNumber,
            primaryGuest: this.guestDetail.lastName + ', ' + this.guestDetail.firstName,
            checkInDate: this.guestDetail.checkInDate,
            checkOutDate: this.guestDetail.checkOutDate,
            room: this.guestDetail.roomType,
            roomNo: this.guestDetail.roomNumber,
            guests: this.guestDetail.adults,
            email: this.guestDetail.email,
            sopagoMinihotelInfo: this.guestDetail.sopagoMinihotelInfo,
            miniHotelReservationId: this.guestDetail.miniHotelReservationId,
            miniHotelMemberSerial: this.guestDetail.miniHotelMemberSerial
        }
        this.dialog.open(CheckInPopupComponent, {
            panelClass: ['share-link_container', 'border-top'],
            width: '440px',
            autoFocus: false,
            data: {
                data: this.checkInData
            }
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((resp) => {
                this.globalService.headerClickAction$.next(null);
                const queryParams: any = {
                    id: this.globalService.selectedGuestId,
                };
                this.snackBar.openFromComponent(SnackBarComponent, {
                    panelClass: ['custom-snackbar'],
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    data: {
                        svgIcon: 'check-circle',
                        message: 'Check-In successful!'
                    }
                });
                setTimeout(() => {
                    this.route.navigate(['/pages/guests/guest'], { queryParams }).then(() => {
                        window.location.reload();
                    });
                }, 500);
            });
    }

    public addPaymentCheckOut() {
        this.addPayment = {
            invoice: this.paymentDetails['total'],
            checkInDate: this.guestDetail.checkInDate + ' - ' + this.guestDetail.checkOutDate,
            billedTo: this.guestDetail.lastName + ', ' + this.guestDetail.firstName,
            amountDue: this.paymentDetails['amountDue'],
            bookingNumber: this.guestDetail.bookingNumber
        }
        this.dialog.open(CheckoutAddPaymentComponent, {
            panelClass: ['share-link_container', 'border-top'],
            width: '440px',
            autoFocus: false,
            data: {
                data: this.addPayment
            }
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((resp) => {
                this.globalService.headerClickAction$.next(null);
                this.getInvoiceDetails();
                this.selectedIndex = 3;
                // if (resp) {
                //     this.openSnackBar('Payment added successfully.');
                // }
            });
    }
    private openSnackBar(message: string) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            panelClass: ['custom-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            data: {
                svgIcon: 'check-circle',
                message
            }
        });
    }
    selectedTabChangedHandler($event) {
        this.selectedTab = $event;

        //Fetch chat only when chat tab is selected
        if ($event == 6) {
            this.getRoomDetails();
        }
    }
    getFormattedDate(date: any) {
        if (date != undefined && date != "") {
            const dateStr = date;
            const parts = dateStr?.split('/');
            return `${parts[1]}/${parts[0].length > 1 ? '' : "0"}${parts[0]}/${parts[2]}`
        }
        else {
            return "-";
        }
    }
}

