import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { takeUntil } from 'rxjs/operators';

import { ConfirmOrderComponent } from '../confirm-order/confirm-order.component';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { AlertDialogComponent } from '../../../shared/components/alert-dialog/alert-dialog.component';

import { OrderService } from '../../../shared/services/order.service';
import { GlobalServices } from '../../../shared/services/global.services';

import { GlobalOrderModel, NotificationModel, SelectOptionsModel } from '../../../shared/models/shared.model';

import { getTotalNumber } from '../../../shared/functions/helpers';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})
@AutoUnsubscribe()
export class OrderDetailsComponent implements OnInit, OnDestroy {

    public orderDetailsForm = new UntypedFormGroup({
        orderStatus: new UntypedFormControl(),
        note: new UntypedFormControl()
    });

    public order: GlobalOrderModel;

    public statusOptionsInitial: SelectOptionsModel[] = [
        {
            label: 'Pending',
            value: 'pending',
        },
        {
            label: 'Processing',
            value: 'processing'
        },
        {
            label: 'Out for Delivery',
            value: 'outfordelivery'
        },
        {
            label: 'Ready for Pick-up',
            value: 'readyforpickup'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        },
        {
            label: 'Picked-up',
            value: 'pickedup'
        },
        {
            label: 'Cancel Order',
            value: 'cancel'
        },
    ];

    public statusOptions;

    public subTotal = 0;
    public discount = 0;
    public totalAmount = 0;
    public tax = 0.00;
    public selectedIndex: any = 0;
    public restaurantId: number;
    public headerName: string = "";
    public orderNumber: any;

    getTotalNumber(value: number) {
        return getTotalNumber(value);
    }

    constructor(public globalService: GlobalServices,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private ordersService: OrderService,
        private restfullservices: RestfullServices,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public datePipe: DatePipe) {
    }

    ngOnInit(): void {
        this.globalService.showLoader$.next(true);
        this.restaurantId = this.activatedRoute.snapshot.queryParams.restaurantId;
        if (this.activatedRoute.snapshot.queryParams.orderNumber) {
            this.orderNumber = this.activatedRoute.snapshot.queryParams.orderNumber;
            this.router.navigate(['/pages/food-beverages/order-details'], {
                queryParams: {
                    orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber,
                    restaurantId: this.activatedRoute.snapshot.queryParams.restaurantId
                }
            });
        }
        //this.setHeaderData();

        // this.ordersService.getOrdersTableData()
        //     .pipe(takeUntil(this['destroy$']))
        //     .subscribe(resp =>
        //         this.order = resp.find(item => item.orderNumber === Number(this.activatedRoute.snapshot.queryParams.orderNumber)));

        this.restfullservices.getOrder({
            restaurantId: this.restaurantId,
            orderNumber: this.orderNumber
        }
        )
            .pipe(takeUntil(this['destroy$']))
            .subscribe(resp => {
                if (resp) {
                    this.order = resp[0]
                    this.headerName = resp[0].restaurantName;
                    this.statusOptions = this.statusOptionsInitial.filter(item => item.value !== this.order?.status);
                    this.subTotal = this.order.total
                    this.totalAmount = this.subTotal;
                    // if (this.order?.orders != undefined) {
                    //     this.order.orders.forEach(order => {
                    //         this.subTotal += Number(order.amount) * order.order.discountedPrice || order.order.price;
                    //     });
                    // }
                    this.setHeaderData();
                    this.orderDetailsForm.get('note').setValue(this.order?.note || '');

                    if (this.activatedRoute.snapshot.queryParams.edited) {
                        this.order.orders = this.ordersService.getOrderConfig();
                        this.editOrder();
                    }
                    setTimeout(() => {
                        this.globalService.showLoader$.next(false);
                    }, 1000)
                }
            });
    }

    ngOnDestroy() { }

    public changeStatus(option) {
        let deliveryModel: NotificationModel = { url: "/#/my-orders", title: this.headerName, message: "Great news! Your order is now on its way.\r\nEstimated delivery time is 30 minutes.", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", orderNumber: this.orderNumber, serviceName: "order" }
        let pickupModel: NotificationModel = { url: "/#/my-orders", title: this.headerName, message: "Great news! Your order is now ready for pick-up. Head over to "+this.headerName+ " to collect your order.", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", orderNumber: this.orderNumber, serviceName: "order" }
        let deliveredModel: NotificationModel = { url: "/#/my-orders", title: this.headerName, message: "Your order [orderNumber] has been delivered!.\r\nEnjoy, [name]! Let us know if there's anything else we can assist you with!", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", orderNumber: this.orderNumber, serviceName: "order" }
        // let pickedUpModel: NotificationModel = { url: "/#/my-orders", title: this.headerName, message: "Great news! Your order is now ready for pick-up.", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", orderNumber: this.orderNumber, serviceName: "order" }

        if (this.order.status === 'pending') {
            if (option.value == "outfordelivery") {
                //push notification
                setTimeout(() =>
                    this.restfullservices.trasactionalNotification(deliveryModel).subscribe(() => {
                    }), 1000)
            }
            if (option.value == "readyforpickup") {
                //push notification
                setTimeout(() =>
                    this.restfullservices.trasactionalNotification(pickupModel).subscribe(() => {
                    }), 1000)
            }
            if (option.value == "delivered") {
                //push notification
                setTimeout(() =>
                    this.restfullservices.trasactionalNotification(deliveredModel).subscribe(() => {
                    }), 1000)
            }
            if (option.value === 'Cancel') {
                this.order.status = "Cancelled";
            }
            else
                this.order.status = option.value;
            this.statusOptions = this.statusOptionsInitial.filter(item => item.value !== this.order.status);
            //this.ordersService.setChangedOrderStatus(this.order.orderNumber, this.order.status);
            this.restfullservices.updateOrderStatus(this.order.orderNumber, this.order.status).subscribe(response => {
                if (response) {
                    this.dialog.open(AlertDialogComponent, {
                        panelClass: 'custom_dialog_container',
                        width: '440px',
                        data: {
                            title: 'Success!!',
                            message: 'Status changed for order No.' + this.order.orderNumber,
                            type: 'message'
                        }
                    });
                }
            });
        }
        else if (this.order.status === 'processing' || this.order.status === 'outfordelivery' || this.order.status === 'readyforpickup' || this.order.status === 'pickedup') {
            if (option.value === 'cancel') {
                var status = "";
                if (this.order?.status == 'outfordelivery') {
                    status = "Out for delivery"
                }
                else if (this.order?.status == 'readyforpickup') {
                    status = "Ready for pickup"
                }
                else if (this.order?.status == 'pickedup') {
                    status = "Picked-up"
                }
                this.dialog.open(AlertDialogComponent, {
                    panelClass: 'custom_dialog_container',
                    width: '440px',
                    data: {
                        title: 'Hoopla!',
                        message: 'This order is already ' + status + ', changing it is no longer possible.',
                        type: 'message'
                    }
                });
            }
            else if (option.value === 'delivered' || option.value === 'outfordelivery' || option.value === 'readyforpickup' || option.value === 'pickedup') {
                if (option.value == "outfordelivery") {
                    //push notification
                    setTimeout(() =>
                        this.restfullservices.trasactionalNotification(deliveryModel).subscribe(() => {
                        }), 1000)
                }
                if (option.value == "readyforpickup") {
                    //push notification
                    setTimeout(() =>
                        this.restfullservices.trasactionalNotification(pickupModel).subscribe(() => {
                        }), 1000)
                }
                if (option.value == "delivered") {
                    //push notification
                    setTimeout(() =>
                        this.restfullservices.trasactionalNotification(deliveredModel).subscribe(() => {
                        }), 1000)
                }
                // if (option.value == "pickedup") {
                //     //push notification
                //     setTimeout(() =>
                //         this.restfullservices.trasactionalNotification(pickupModel).subscribe(() => {
                //         }), 1000)
                // }
                this.order.status = option.value;
                this.statusOptions = this.statusOptionsInitial.filter(item => item.value !== this.order.status);
                //this.ordersService.setChangedOrderStatus(this.order.orderNumber, this.order.status);
                this.restfullservices.updateOrderStatus(this.order.orderNumber, this.order.status).subscribe(response => {
                    if (response) {
                        this.dialog.open(AlertDialogComponent, {
                            panelClass: 'custom_dialog_container',
                            width: '440px',
                            data: {
                                title: 'Success!!',
                                message: 'Status changed for order No.' + this.order.orderNumber,
                                type: 'message'
                            }
                        });
                    }
                });
            }
        }
        this.restfullservices.getOrderList(this.restaurantId, 0, 0).subscribe(response => {
            if (response) {
                this.ordersService.testOrdersInstance = response;
            }
        })
    }
    public newDate(input) {
        return this.datePipe.transform(new Date(input), "dd MMM yyyy HH:mm:ss").toLocaleString();
    }

    public editOrder() {
        if (this.globalService.isMobile) {
            this.dialog.open(ConfirmOrderComponent, {
                panelClass: ['share-link_container', 'border-top'],
                width: '440px',
                data: {
                    itemsToOrder: this.order.orders
                }
            }).afterClosed()
                .pipe(takeUntil(this['destroy$']))
                .subscribe((resp) => {
                    if (resp) {
                        this.order.orders = resp;
                        this.subTotal = 0;
                        this.totalAmount = 0;
                        this.order.orders.forEach(order => {
                            this.subTotal += order.amount * order.order.discountedPrice || order.order.price;
                            this.totalAmount = this.subTotal;
                        });
                        if (resp) {
                            this.snackBar.openFromComponent(SnackBarComponent, {
                                panelClass: ['custom-snackbar'],
                                horizontalPosition: 'right',
                                verticalPosition: 'top',
                                data: {
                                    svgIcon: 'check-circle',
                                    message: 'Order edited successfully.'
                                }
                            });
                        }
                    }

                });
        } else {
            this.router.navigate(['/pages/food-beverages/add-order'], {
                queryParams: {
                    orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber,
                    restaurantId: this.activatedRoute.snapshot.queryParams.restaurantId
                }
            });
        }
    }
    navigateToOrders() {
        this.router.navigate(['/pages/food-beverages/orders'], {
            queryParams: {
                orderNumber: this.activatedRoute.snapshot.queryParams.restaurantId,
                id: this.activatedRoute.snapshot.queryParams.restaurantId
            }
        });
    }
    private setHeaderData() {
        this.selectedIndex = 0;
        const config: any = {
            showBackButton: true,
            navigationPage: '/pages/food-beverages/orders'
        };
        config.queryParams = { id: this.restaurantId };
        this.globalService.isMobile
            ? config.headerTitle = this.headerName
            : config.info1 = this.headerName;


        this.globalService.headerData$.next(config);
        this.globalService.tabIndexSet.next(this.selectedIndex)
        this.restfullservices.setLocalStorage('tabindex', this.selectedIndex);
    }
    getDeliverType(type: string) {
        if (type == 'pickup') {
            return "Pickup"
        } else if (type == 'roomService') {
            return "Room Service"
        }
    }
    capitalizeFirstLetter(str) {
        if (!str) return ''
        if (str.length === 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    getTotalAmount(discountedPrice, price, amt) {
        if (discountedPrice) {
            return discountedPrice * amt
        } else {
            return price * amt
        }
    }
}
