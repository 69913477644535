import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { GuestDetailModel, GuestModelSpa, HeaderModel } from 'src/app/shared/models/shared.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as introJs from 'intro.js';

@Component({
  selector: 'app-add-reservation',
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.scss']
})
export class AddReservationComponent implements OnInit {
  introJS = introJs.default();
  @Input() guestDetail: GuestDetailModel;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  activeOption
  public guestForm: UntypedFormGroup;
  peopleOpt = [];
  reservationTime = [];
  tableNumberList = [];
  reservationStatus = [];
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  selectedTime: string;
  time: string;
  userGroup: GuestModelSpa[] = [];
  filteredUser: Observable<string[] | GuestModelSpa[]>;
  reservationId: any;
  guestId: any
  reservationDate: any
  guestName: string;
  currentStatus: string = "Confirmed";
  public selectedIndex: any = 1;
  constructor(
    public globalService: GlobalServices,
    private fb: UntypedFormBuilder,
    private restfullServices: RestfullServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.orderNumber) {
      this.router.navigate(['/pages/food-beverages/add-reservation'], {
        queryParams: {
          orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber,
          restaurantId:this.activatedRoute.snapshot.queryParams.restaurantId
        }
      });
    }
    if (this.activatedRoute.snapshot.queryParams.id) {
      this.router.navigate(['/pages/food-beverages/add-reservation'], {
        queryParams: {
          id: this.activatedRoute.snapshot.queryParams.id,
          restaurantId:this.activatedRoute.snapshot.queryParams.restaurantId
        }
      });
    }
    this.loadForm();
    this.setPageData();
    this.initializeData();
    this.filteredUser = this.guestForm.controls['guestName'].valueChanges.pipe(
      startWith(''),
      map(value => {
        this.guestName = typeof value === 'string' ? value : value?.guestName;
        return this.guestName ? this._filter(this.guestName as string) : this.userGroup.slice();
      }),
    );
    this.setHeaderData();
  }



  private _filter(value: string): GuestModelSpa[] {
    const filterValue = value.toLowerCase();
    return this.userGroup.filter(option => option.guestName.toLowerCase().includes(filterValue));
  }

  displayFn(guest: GuestModelSpa): string {
    return guest && guest.guestName ? guest.guestName : '';
  }

  setPageData() {
    this.selectedTime = 'hh:mm'
    this.time = 'Select';
    this.guestForm.controls['status'].setValue(this.currentStatus);
    this.reservationTime = [...this.setWakeUpCallTime()];
    this.tableNumberList = [
      { value: "1", viewValue: "Table No. 01 (1-2 Persons)" },
      { value: "2", viewValue: "Table No. 02 (1-2 Persons)" },
      { value: "3", viewValue: "Table No. 03 (3-4 Persons)" },
      { value: "4", viewValue: "Table No. 04 (1-2 Persons)" },
      { value: "5", viewValue: "Table No. 05 (5 Persons)" },
      { value: "6", viewValue: "Table No. 06 (5-8 Persons)" },
    ];

    this.peopleOpt = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },

    ];

    this.reservationStatus = [
      { label: 'New Request', value: 'New Request' },
      { label: 'Confirmed', value: 'Confirmed' },
      { label: 'Seated', value: 'Seated' },
      { label: 'No Show', value: 'No Show' },
      { label: 'Cancel', value: 'Cancelled' },
    ];

  }

  initializeData() {
    this.restfullServices.getGuestListWithId()
      .subscribe(
        response => {
          if (response) {
            this.userGroup = response;
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));
  }

  public changeStatus(option) {
    this.currentStatus = option.value;
    this.guestForm.controls['status'].setValue(this.currentStatus);
  }

  loadForm() {
    this.createForm();
    this.activatedRoute.queryParams.subscribe((res: any) => {
      if (res) {
        //console.log(res)
        let data ={};
        if (res.orderNumber) {
          this.reservationId = res.orderNumber;
           data = {
            id: this.reservationId
          }
        }
        else {
          this.reservationId = res.id;
           data = {
            id: this.reservationId
          }
        }
        if (this.reservationId) {
          this.restfullServices.getReservationById(data).subscribe((res) => {
            //console.log(res)
            if (res.status == "Cancelled") {
              this.currentStatus = "Cancelled"
            }
            else {
              this.currentStatus = res.status;
            }
            this.reservationStatus = this.reservationStatus.filter(x => x.value != res.status);
            this.reservationDate = this.datePipe.transform(res.dateAndTime, 'yyyy-MM-dd');
            this.guestForm.controls["id"].setValue(Number(res?.id));
            this.guestForm.controls["guestName"].setValue(res?.guestName);
            this.guestForm.controls["guestAspeople"].setValue(String(res?.people));
            this.guestForm.controls['selectedTime'].setValue(this.convertTimeTo24(this.datePipe.transform(res?.dateAndTime, 'shortTime')));
            //this.guestForm.controls["selectedTime"].setValue(res?.time);
            this.guestForm.controls["reservation_Date"].setValue(this.reservationDate);
            this.guestForm.controls["notes"].setValue(res?.note);
            this.guestForm.controls["tableNo"].setValue(res?.tableNumber);
          });
        }
      }
    });

  }

  public createForm() {
    this.guestForm = this.fb.group({
      id: [null],
      guestName: ['', Validators.required],
      guestAspeople: [null],
      selectedTime: [null],
      reservation_Date: [this.datePipe.transform(new Date(), 'dd/MM/YYYY')],
      sdate: [this.datePipe.transform(new Date(), 'dd/MM/YYYY')],
      notes: [null],
      tableNo: [null],
      bookedOpt: [''],
      status: [''],
    });
  }

  setWakeUpCallTime() {
    let hourArr = [
      // { value: 'anytime', viewValue: 'Anytime' }
    ];
    for (var i = 1; i <= 23; i++) {
      hourArr.push({
        value: (i < 10 ? "0" + i + ":00 am" : (i > 12 ? i - 12 + ":00 pm" : i + ":00 am")),
        viewValue: (i < 10 ? "0" + i + ":00 am" : (i > 12 ? i - 12 + ":00 pm" : i + ":00 am"))
      });
    }
    return hourArr;
  }

  save(type) {
    if (type == 'save') {
      if (this.guestForm.invalid) {
        return
      }
      //Save Table resevation data to db api
      let payload = {
        id: 0,
        guestId: +this.guestForm.controls['guestName'].value.id,
        guests: this.guestForm.controls['guestAspeople'].value,
        time: this.convertTime(this.guestForm.controls['selectedTime'].value),
        date: this.datePipe.transform(this.guestForm.controls['reservation_Date'].value, 'dd/MM/YYYY'),
        note: this.guestForm.controls['notes'].value,
        //  tableNumber: this.guestForm.controls['tableNo'].value,
        status: this.guestForm.controls['status'].value,
        isReserved: 0,
        restaurantId:this.activatedRoute.snapshot.queryParams.restaurantId
      };
      this.globalService.showLoader$.next(true);
      this.restfullServices.addTableReservation(payload).subscribe(response => {
        if (response) {
          this.router.navigate(['/pages/food-beverages/orders'],{
            queryParams: {id: this.activatedRoute.snapshot.queryParams.restaurantId }
        });
          this.globalService.showLoader$.next(false);
        }
      });
    } else {
      let payload = {
        id: this.guestForm.controls['id'].value,
        guestId: +this.guestForm.controls['guestName'].value.id,
        guests: +this.guestForm.controls['guestAspeople'].value,
        time: this.convertTime(this.guestForm.controls['selectedTime'].value),
        date: this.datePipe.transform(this.guestForm.controls['reservation_Date'].value, 'dd/MM/YYYY'),
        note: this.guestForm.controls['notes'].value,
        //   tableNumber: this.guestForm.controls['tableNo'].value,
        status: this.guestForm.controls['status'].value,
        isReserved: 1,
        restaurantId:this.activatedRoute.snapshot.queryParams.restaurantId
      };
      this.globalService.showLoader$.next(true);
      this.restfullServices.addTableReservation(payload).subscribe(response => {
        if (response) {
          this.router.navigate(['/pages/food-beverages/orders'],{
            queryParams: {id: this.activatedRoute.snapshot.queryParams.restaurantId }
        });
          this.globalService.showLoader$.next(false);
        }
      });
    }
  }

  public getFormControl(formControlName: string) {
    return this.guestForm.get(formControlName);
  }

  startDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.guestForm.controls['sdate'].setValue(this.datePipe.transform(event.value, 'dd/MM/YYYY'));
  }

  /** Set Date Format */
  getDate(toDate: Date) {
    return (toDate.getMonth() + 1) + '/' + toDate.getDate() + '/' + toDate.getFullYear();
  }

  convertTime(input: string) {
    let time = input.split(":");
    let hour = time[0];
    let min = time[1];
    let suffix = "";
    if (+hour > 12) {
      hour = (+hour - 12).toString();
      suffix = "pm";
    }
    else
      suffix = "am";
    //hour = +hour<10?"0"+hour:hour;
    return hour + ":" + min + " " + suffix;
  }

  convertTimeTo24(input: string) {
    let hours = Number(input.match(/^(\d+)/)[1]);
    let minutes = Number(input.match(/:(\d+)/)[1]);
    const AMPM = input.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() === "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() === "am" && hours == 12) hours = hours - 12;

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return `${sHours}:${sMinutes}`;
  }


  private setHeaderData() {
    this.selectedIndex = 1;
    const config: any = {
      showBackButton: true,
      navigationPage: '/pages/food-beverages/orders',
      queryParams:{id:this.activatedRoute.snapshot.queryParams.restaurantId}
    };

    this.globalService.isMobile
      ? config.headerTitle = 'Reservation details'
      : config.info1 = 'Reservation details';

    this.globalService.headerData$.next(config);
    this.globalService.tabIndexSet.next(this.selectedIndex)
    this.restfullServices.setLocalStorage('tabindex', this.selectedIndex);
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          tooltipClass: "intro-step-add_reservation",
          title: "ADD NEW RESERVATION",
          element: document.getElementById('place_order'),
          intro: "Well done! This is where you can add a new reservation for your customers by entering all the necessary information.",
          position: 'right'
        },


      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: 'tooltip',
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener('click', this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener('click', this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage('isTourRunning');
    window.location.href = '#/pages/food-beverages';
  }

  closeStep(): void {
    window.location.href = '#/pages/food-beverages';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
        const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
        const guidedTourAddReservationFlag =  this.restfullServices.getLocalStorage('guidedTourAddReservationFlag');
        if (isTourRunning == 'true' && isFirstLogin=='false' && guidedTourAddReservationFlag=='false') {
          this.restfullServices.setLocalStorage('guidedTourAddReservationFlag','true');
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }
}

