import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HouseKeepingCategoryModel, SelectOptionsModel, ViewCleaningDialogModel, ViewRequestDialogModel } from 'src/app/shared/models/shared.model';
import { ViewRequestComponent } from '../view-request/view-request.component';

@Component({
  selector: 'app-view-cleaning-request',
  templateUrl: './view-cleaning-request.component.html',
  styleUrls: ['./view-cleaning-request.component.scss']
})
export class ViewCleaningRequestComponent implements OnInit {

  public cleaningData: HouseKeepingCategoryModel[] = [];
  public requestStatus: number | string;
  public roomNumber: string;
  public cleaningDateTime:string;
  public requestNote: string = null;
  public requestTotal = 0;

  public selectOptions: SelectOptionsModel[] = [
      {
          label: 'Open',
          value: 1
      },
      {
          label: 'Cleaned',
          value: 2
      }
  ];

  constructor(
      public dialogRef: MatDialogRef<ViewCleaningRequestComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ViewCleaningDialogModel
  ) {
  }

  ngOnInit(): void {
    //   this.data.houseKeepingCategory.map(data => {
    //       this.cleaningData.push({
    //           id: data.id,
    //           name: data.name,
    //           houseKeepingRequest: this.data.requestDetails.filter(details => details.requestCategory === data.id)
    //       });
    //   });
    //   this.data.requestDetails.map(details => {
    //       if (details.requestCategory === 0 && details.requestNote) {
    //           this.requestNote = atob(details.requestNote);
    //       }
    //   });
      //this.requestData.map(data => this.requestTotal += data.houseKeepingRequest.length);
      this.requestStatus = this.data.requestDetails[0]?.cleaningStatus || this.selectOptions[0].value;
      this.roomNumber = this.data.requestDetails[0]?.roomNumber;
      this.cleaningDateTime = this.data.requestDetails[0]?.cleaningDate + ", " + this.data.requestDetails[0]?.cleaningTime  ;
      //console.log("housekeeping data",this.data)
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

}
