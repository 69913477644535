import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { AlertDialogComponent } from "src/app/shared/components/alert-dialog/alert-dialog.component";
import { getTotalNumber } from "src/app/shared/functions/helpers";
import {
  GlobalOrderModel,
  GlobalSpaModel,
  NotificationModel,
  SelectOptionsModel,
} from "src/app/shared/models/shared.model";
import { GlobalServices } from "src/app/shared/services/global.services";
import { OrderService } from "src/app/shared/services/order.service";
import { RestfullServices } from "src/app/shared/services/restfull.services";
import { SpaAddPaymentComponent } from "../spa-add-payment/spa-add-payment.component";
import { SnackBarComponent } from "src/app/shared/components/snack-bar/snack-bar.component";
import { AddEventHandlerComponent } from "../add-event-handler/add-event-handler.component";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-spa-details",
  templateUrl: "./spa-details.component.html",
  styleUrls: ["./spa-details.component.scss"],
})
export class SpaDetailsComponent implements OnInit {
  public orderDetailsForm = new UntypedFormGroup({
    orderStatus: new UntypedFormControl(),
    note: new UntypedFormControl(),
  });

  public order: any;
  public spaId: number;
  public guestId: number;
  public hotelSpaName: string;

  public statusOptionsInitial: SelectOptionsModel[] = [
    {
      label: "Open",
      value: "open",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Cancel Appointment",
      value: "cancelled",
    },
  ];

  public statusOptions;

  public subTotal = 0;
  public discount = 0;
  public totalAmount = 0;
  public tax = 0.0;

  getTotalNumber(value: number) {
    return getTotalNumber(value);
  }
  constructor(
    public globalService: GlobalServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ordersService: OrderService,
    private restfullservices: RestfullServices,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.spaId = this.activatedRoute.snapshot.queryParams.spaId;
    // this.guestId = this.activatedRoute.snapshot.queryParams.guestId;
    if (this.activatedRoute.snapshot.queryParams.spaId) {
      this.router.navigate(["/pages/spa-wellness/spa-details"], {
        queryParams: {
          spaId: this.activatedRoute.snapshot.queryParams.spaId,
        },
      });
    }
    this.getSpaDetail()
  }

  getSpaDetail() {
    this.globalService.showLoader$.next(true);
    let payload = {
      spaId: +this.spaId,
      //guestId: this.guestId
    };
    this.restfullservices.getSpaDetail(payload).subscribe((resp) => {
      if (resp) {
        this.order = resp;
        this.hotelSpaName = resp.hotelSpaName;
        this.statusOptions = this.statusOptionsInitial.filter(
          (item) => item.value !== this.order.status
        );
        if (this.order != undefined) {
          this.subTotal = this.toNumber(this.order.totalAmount) || this.toNumber(this.order.price);
          this.totalAmount = this.toNumber(this.order.totalAmount) || this.toNumber(this.order.price);
        }

        this.setHeaderData();
        this.orderDetailsForm.get("note").setValue(this.order?.note || "");
        this.globalService.showLoader$.next(false);

      }
    });
  }
  ngOnDestroy() { }

  public changeStatus(option) {
    if (this.order.status === "open") {
      //Add payment
      if (option.value == "completed") {
        if (this.order.amountPaid !== this.totalAmount) {
          this.dialog
            .open(SpaAddPaymentComponent, {
              panelClass: ["share-link_container", "border-top"],
              width: "440px",
              autoFocus: false,
              data: this.order,
            })
            .afterClosed()
            .subscribe((resp) => {
              this.globalService.headerClickAction$.next(null);
              if (resp) {
                this.openSnackBar("Payment added successfully.");
                this.order.status = option.value;
                this.statusOptions = this.statusOptionsInitial.filter(
                  (item) => item.value !== this.order.status
                );
                this.updateStatustoComplete(option.value);
              }
            });
        } else {
          this.updateStatustoComplete(option.value);
        }
      } else if (option.value == "cancelled") {
        //this.ordersService.setChangedOrderStatus(this.order.orderNumber, this.order.status);
        this.order.status = option.value;

        this.statusOptions = this.statusOptionsInitial.filter(
          (item) => item.value !== this.order.status
        );
        this.restfullservices
          .updateSpaStatus(this.order.orderNumber, this.order.status)
          .subscribe((response) => {
            if (response) {
              //Show push notification
              let spaModel: NotificationModel = { url: "/#/services/spa", title: this.hotelSpaName, message: "\r\nBooking cancelled!\r\nYour spa booking has been cancelled. Please contact frontdesk for any assistance!", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", serviceName: "spa", orderNumber: this.order.orderNumber };
              setTimeout(() =>
                this.restfullservices.trasactionalNotification(spaModel).subscribe(() => {
                }), 1000)

              this.dialog.open(AlertDialogComponent, {
                panelClass: "custom_dialog_container",
                width: "440px",
                data: {
                  title: "Success!!",
                  message:
                    "Status changed for order No." + this.order.orderNumber,
                  type: "message",
                },
              });
            }
          });
      }
    }
  }
  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ["custom-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        svgIcon: "check-circle",
        message,
      },
    });
  }
  private setHeaderData() {
    const config: any = {
      showBackButton: true,
      navigationPage: "/pages/spa-wellness",
    };

    this.globalService.isMobile
      ? (config.headerTitle = this.order?.guestName.guestName)
      : (config.info1 = this.order?.guestName.guestName);

    this.globalService.headerData$.next(config);
  }

  private updateStatustoComplete(status: string) {
    this.restfullservices
      .updateSpaStatus(this.order.orderNumber, status)
      .subscribe((response) => {
        if (response) {
          let spaModel: NotificationModel = { url: "/#/services/spa", title: this.hotelSpaName, message: "\r\nShare your feedback on your recent spa treatment. Your opinion matters to us!", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", serviceName: "spa", orderNumber: this.order.orderNumber };
          setTimeout(() =>
            this.restfullservices.trasactionalNotification(spaModel).subscribe(() => {
            }), 1000)
          this.getSpaDetail()
          // this.dialog.open(AlertDialogComponent, {
          //   panelClass: 'custom_dialog_container',
          //   width: '440px',
          //   data: {
          //     title: 'Success!!',
          //     message: 'Status changed for order No.' + this.order.orderNumber,
          //     type: 'message'
          //   }
          // });
        }
      });
  }
  toNumber(input: string) {
    if (input != undefined && input != "")
      return parseFloat(input.replace(/,/g, ""));
  }
  navigateToOrders() {
    this.router.navigate(["/pages/spa-wellness"]);
  }
  capitalizeFirstLetter(str) {
    if (!str) return "";
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  getInvoiceNo(invoiceNo: any) {
    let formattedInvoice = "00000000";
    invoiceNo = this.activatedRoute.snapshot.queryParams.spaId.toString();
    return `${formattedInvoice.slice(
      0,
      formattedInvoice.length - invoiceNo.length
    )}${invoiceNo}`;
  }
  getPaymentStatus(paymentStatus: string, status: string) {

    if (status == "cancelled") return "Cancelled";
    if (paymentStatus == "paid") return "Paid";
    if (paymentStatus == "unpaid") return "Unpaid";
    if (paymentStatus == "billing") return "Direct Billing";
    if (paymentStatus == "cancelled") return "Cancelled";
  }
  onEditEvent() {
    this.dialog
      .open(AddEventHandlerComponent, {
        panelClass: ["share-link_container", "border-top"],
        width: "440px",
        data: {
          eventType: "isEdit",
          order: this.order
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.data?.length != 0) {
          this.openSnackBar("Appointment Updated!")
          //# - Further Code to edit event
        }
        this.getSpaDetail()
      });
  }

}
