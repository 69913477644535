import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GuestModelSpa } from 'src/app/shared/models/shared.model';
import { DatePipe, DecimalPipe } from '@angular/common';
import * as introJs from 'intro.js';
import { filter } from "rxjs/operators";
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-event-handler',
  templateUrl: './add-event-handler.component.html',
  styleUrls: ['./add-event-handler.component.scss']
})
export class AddEventHandlerComponent implements OnInit, AfterViewInit {
  introJS = introJs.default();
  addEventForm: UntypedFormGroup;
  servicesGroup: any = [];
  serviceDuration: string = "";
  selectedService: any | null;// [{name:"",services:[{value:"",viewValue:"",spaId:0}]}];
  filteredUser: Observable<string[] | GuestModelSpa[]>;
  userGroup: GuestModelSpa[] = [];
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  hourList1 = new Array(23).fill(null).map((_, i) => i + 1);
  hourList = this.hourList1.map(x => x < 10 ? "0" + x : x);
  minList1 = new Array(59).fill(null).map((_, i) => i + 1);
  minList = this.minList1.map(x => x < 10 ? "0" + x : x);
  Assignee = [
    { value: 'Maina, Zoya', viewValue: 'Maina, Zoya' },
    { value: 'Kimani, Aysha', viewValue: 'Kimani, Aysha' },
    { value: 'Ouma, Shani', viewValue: 'Ouma, Shani' },
    { value: 'Muthoni, Chege', viewValue: 'Muthoni, Chege' },
  ];
  endTime = ["15 mins", "30 mins", "45 mins", "60 mins", "90 mins", "120 mins"]
  currency
  pricePerMinute
  constructor(
    public dialogRef: MatDialogRef<AddEventHandlerComponent>, private restfullServices: RestfullServices, private globalService: GlobalServices, private dialog: MatDialog, private datePipe: DatePipe, private router: Router,
    private decimalPipe: DecimalPipe, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef
  ) { }


  isEdit: boolean = false;
  ngOnInit(): void {
    this.globalService.showLoader$.next(true);
    this.currency = JSON.parse(localStorage.getItem('loggedInHotelCurrency'))
    this.initializeData();
    this.addEventForm = new UntypedFormGroup({
      dtb: new UntypedFormControl('', Validators.required),
      startTime: new UntypedFormControl('', Validators.required),
      // startHour: new FormControl('', Validators.required),
      // startMin: new FormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl('', Validators.required),
      guestName: new UntypedFormControl('', Validators.required),
      serviceType: new UntypedFormControl('', Validators.required),
      assignee: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl(''),
      totalAmount: new UntypedFormControl(''),
    });
    if (this.data?.eventType == "isEdit") {
      this.isEdit = true;
      if (this.data?.event) {
        let event = this.data?.event;
        let endDate = new Date(event?.event?._def?.extendedProps.dateAndTime);
        let durationSplit = event?.event?._def?.extendedProps.durationTime?.split(' ');
        endDate.setMinutes(endDate.getMinutes() + (+durationSplit[0]));
        if (!this.endTime.includes(durationSplit[0] + " mins")) this.endTime.push(durationSplit[0] + " mins")
        this.addEventForm.controls['guestName'].setValue(event?.event?._def?.extendedProps.guestName);
        this.addEventForm.controls['dtb'].setValue(event?.event?._def?.extendedProps.dateAndTime);
        this.addEventForm.controls['startTime'].setValue(this.convertTimeTo24(this.datePipe.transform(event?.event?._def?.extendedProps.dateAndTime, 'shortTime')));
        this.addEventForm.controls['endTime'].setValue(this.convertTimeTo24(this.datePipe.transform(endDate, 'shortTime')));
        this.addEventForm.controls['duration'].setValue((durationSplit[0] + " mins") || event?.event?._def?.extendedProps.durationTime);
        this.addEventForm.controls['serviceType'].setValue(event?.event?._def?.extendedProps.spaDetails.services.spaId);
        this.addEventForm.controls['assignee'].setValue(event?.event?._def?.extendedProps.assign);
        this.addEventForm.controls['note'].setValue(event?.event?._def?.extendedProps.note);
      } else {
        let endDate = new Date(this.data.order.dateAndTime);
        let durationSplit = this.data.order?.durationTime?.split(' ');
        if (durationSplit) {
          endDate.setMinutes(endDate.getMinutes() + (+durationSplit[0]));
          if (!this.endTime.includes(durationSplit[0] + " mins")) this.endTime.push(durationSplit[0] + " mins")
        }
        this.addEventForm.controls['guestName'].setValue(this.data.order.guestName);
        this.addEventForm.controls['dtb'].setValue(this.data.order.dateAndTime);
        this.addEventForm.controls['startTime'].setValue(this.convertTimeTo24(this.datePipe.transform(this.data.order.dateAndTime, 'shortTime')));
        this.addEventForm.controls['endTime'].setValue(this.convertTimeTo24(this.datePipe.transform(endDate, 'shortTime')));
        this.addEventForm.controls['duration'].setValue((durationSplit[0] + " mins") || this.data.order.durationTime);
        console.log(this.addEventForm.controls['duration'].value)
        this.addEventForm.controls['serviceType'].setValue(this.data.order.spaDetails.services.spaId);
        this.addEventForm.controls['assignee'].setValue(this.data.order.assign);
        this.addEventForm.controls['note'].setValue(this.data.order.note);
      }
      //# --  Set remaining value from as per new props
    }
    else {
      //let date = this.days[this.data?.event?.date.getDay()] + ", " + this.data?.event?.date.getDate() + " " + this.months[this.data?.event?.date.getMonth()] + " " + this.data?.event?.date.getFullYear();
      this.addEventForm.controls['dtb'].setValue(this.data?.event?.date)
      // var startHour = new Date(this.data?.event?.date).getHours();
      // var startMin = new Date(this.data?.event?.date).getMinutes();
      // this.addEventForm.controls['startHour'].setValue(startHour < 10 ? "0" + startHour : startHour);
      // this.addEventForm.controls['startMin'].setValue(startMin < 10 ? "0" + startMin : startMin);
      //this.addEventForm.controls['endTime'].setValue(this.datePipe.transform(this.data?.event?.date.setTime(this.data?.event?.date.getTime() + 1 * 60 * 60 * 1000), 'shortTime'))
    }

    this.filteredUser = this.addEventForm.controls['guestName'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.guestName;
        return name ? this._filter(name as string) : this.userGroup.slice();
      }),
    );
  }

  getTotalAmount() {
    let service = null;
    this.servicesGroup.forEach(element => {
      const foundService = element.services.find(item =>
        item.spaId === (this.data?.event?.event?._def?.extendedProps?.spaDetails?.services?.spaId ||
          this.data?.order?.spaDetails?.services?.spaId ||
          this.addEventForm.controls['serviceType'].value)
      );
      if (foundService) {
        service = foundService;
      }
    });
    if (service) {
      const servicePrice = Number(service.price.replace(/,/g, ''));
      const serviceDuration = Number(service.duration.split(" ")[0]);
      const appointmentDuration = Number(this.addEventForm?.controls['duration'].value?.split(" ")[0]);
      const totalAmount = (servicePrice / serviceDuration) * appointmentDuration;
      this.pricePerMinute = (servicePrice / serviceDuration)
      const formattedAmount = this.decimalPipe.transform(totalAmount, '1.2-2');
      this.addEventForm.controls['totalAmount'].setValue(this.data.event?.event?._def?.extendedProps.totalAmount || this.data.order.totalAmount || formattedAmount);
      this.globalService.showLoader$.next(false);
    }
  }

  displayFn(guest: GuestModelSpa): string {
    return guest && guest.guestName ? guest.guestName : '';
  }

  private _filter(value: string): GuestModelSpa[] {
    const filterValue = value.toLowerCase();
    return this.userGroup.filter(option => option.guestName.toLowerCase().includes(filterValue));
  }

  initializeData() {
    this.globalService.showLoader$.next(true);
    this.restfullServices.getSpaServiceList()
      .subscribe(
        response => {
          if (response) {
            this.servicesGroup = response;
            if (this.data?.eventType == "isEdit") {
              this.getTotalAmount()
            } else {
              this.globalService.showLoader$.next(false);
            }
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));

    this.restfullServices.getGuestListWithId()
      .subscribe(
        response => {
          if (response) {
            this.userGroup = response?.filter(element =>
              element.status == 'active' ||
              element.status == 'checkedIn' ||
              element.status == 'confirmed'
            );
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));

    //Get spa assistants list
    this.restfullServices.getSpaAssistantsList()
      .subscribe(
        response => {
          if (response.length != 0) {
            this.Assignee = response;
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));
  }

  onSubmit() {
    Object.keys(this.addEventForm.controls).forEach(field => {
      const control = this.addEventForm.get(field);
      control.markAsTouched();
      control.markAsDirty();
    });
    if (this.addEventForm.valid) {

      if (this.data?.order && this.data?.order?.status !== 'open') return

      let response = {
        isAdd: true,
        eventObj: this.addEventForm
      }
      //Save data to db api
      let payload = {
        bookingId: this.data?.event?.event?._def?.extendedProps.itemId || this.data?.order?.itemId,
        status: 1,
        spaId: +this.addEventForm.controls['serviceType'].value,
        guestId: +this.addEventForm.controls['guestName'].value.id,
        bookingDate: this.days[new Date(this.addEventForm.controls['dtb'].value).getDay()] + ", " + new Date(this.addEventForm.controls['dtb'].value).getDate() + " " + this.months[new Date(this.addEventForm.controls['dtb'].value).getMonth()] + " " + new Date(this.addEventForm.controls['dtb'].value).getFullYear(),
        bookingTime: this.convertTime(this.addEventForm.controls['startTime'].value),
        bookingNote: this.addEventForm.controls['note'].value,
        dateTime: new Date(),
        isBooked: this.data?.eventType == "isEdit" ? 1 : 0, // flag to add or update booking
        paymentStatus: "unpaid",
        transactionStatus: "open",
        bookedBy: this.addEventForm.controls['assignee'].value,
        duration: this.addEventForm.controls['duration'].value,
        totalAmount: this.addEventForm.controls['totalAmount'].value.toString()
      }
      this.restfullServices.bookSpa(payload)
        .subscribe(
          response => {
            if (response) {
              this.dialogRef.close({ data: response })
            }
          },
          () => this.globalService.showAlert(this.globalService.errorMsg));
    }
  }

  onCancel() {
    // let response = {
    //   isAdd: false,
    //   eventObj: this.addEventForm
    // }
    this.dialogRef.close({ data: new Array })
  }

  serviceChange(event: any) {
    let duration = "";
    let price
    this.servicesGroup.forEach(element => {
      return element.services.forEach(service => {
        if (service.spaId == event.value) {
          price = service.price
          duration = service.duration;
        }
      });
    });

    this.pricePerMinute = (Number(price.replace(/,/g, '')) / Number(duration.split(" ")[0]))
    this.serviceDuration = `${Number(duration.split(" ")[0])} mins`;
    if (!this.endTime.includes(this.serviceDuration)) this.endTime.push(this.serviceDuration)
    this.addEventForm.controls['duration'].setValue(`${Number(duration.split(" ")[0])} mins`);

    const totalAmount = this.pricePerMinute * Number(this.serviceDuration.split(" ")[0]);
    const formattedAmount = this.decimalPipe.transform(totalAmount, '1.2-2');
    this.addEventForm.controls['totalAmount'].setValue(formattedAmount);

    if (this.addEventForm.controls['startTime'].value != null || this.addEventForm.controls['startTime'].value != undefined) {
      this.addEventForm.controls['endTime'].setValue(this.addtime(this.addEventForm.controls['startTime'].value, this.serviceDuration.split(" ")[0]));
    }
  }

  onSearchChange(event: any) {
    this.addEventForm.controls['endTime'].setValue(this.addtime(event, this.serviceDuration.split(" ")[0]));
  }

  durationChange(event: any) {
    this.serviceDuration = event.value;

    const totalAmount = this.pricePerMinute * Number(this.addEventForm.controls['duration'].value.split(" ")[0]);
    const formattedAmount = this.decimalPipe.transform(totalAmount, '1.2-2');
    this.addEventForm.controls['totalAmount'].setValue(formattedAmount);

    this.addEventForm.controls['endTime'].setValue(this.addtime(this.addEventForm.controls['startTime'].value, this.serviceDuration.split(" ")[0]));
  }

  addtime(time: string, min: string) {
    // Convert input time to hours and minutes
    let times = time.split(":");
    let hours = parseInt(times[0]);
    let minutes = parseInt(times[1]);

    // Convert duration to minutes
    let duration = parseInt(min);

    // Add duration to current time
    minutes += duration;

    // Adjust hours if minutes exceed 60
    if (minutes >= 60) {
      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;
    }

    // Handle 24-hour clock rollover
    hours = hours % 24;

    // Pad single digit hours and minutes with leading zero
    let formattedHours = hours < 10 ? "0" + hours : hours.toString();
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();

    return `${formattedHours}:${formattedMinutes}`;
  }
  convertTime(input: string) {
    let time = input.split(":");
    let hour = time[0];
    let min = time[1];
    let suffix = "";
    if (+hour > 12) {
      hour = (+hour - 12).toString();
      suffix = "pm";
    }
    else
      suffix = "am";
    return hour + ":" + min + " " + suffix;
  }

  convertTimeTo24(input: string) {
    let hours = Number(input.match(/^(\d+)/)[1]);
    let minutes = Number(input.match(/:(\d+)/)[1]);
    const AMPM = input.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() === "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() === "am" && hours == 12) hours = hours - 12;

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return `${sHours}:${sMinutes}`;
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 19
          tooltipClass: "intro-step-19",
          title: "NEW APPOINTMENT",
          element: document.getElementById('add_appointment'),
          intro: "Well done! This is where you can enter all necessary details of your customer/guest for a new appointment.",
          position: 'right'
        },


      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: 'tooltip',
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener('click', this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener('click', this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage('isTourRunning');
    window.location.href = '#/pages/spa-wellness';
  }

  closeStep(): void {
    window.location.href = '#/pages/spa-wellness';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
        const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
        const guidedTourAddEventFlag = this.restfullServices.getLocalStorage('guidedTourAddEventFlag');
        if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourAddEventFlag == "false") {
          this.restfullServices.setLocalStorage('guidedTourAddEventFlag', 'true');
          guidedTourAddEventFlag
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }
  cancelRequest(status: string) {
    if (this.data?.order && this.data?.order?.status !== 'open') return

    const str =
      "Are you sure you want to cancel this appointment? Once cancelled, this action cannot be undone.";
    const str2 =
      "Are you sure you want to delete this request? Once deleted, it cannot be undone.";
    const alertTitle = "Cancel Appointment?";
    const alertTitle2 = "Delete Request?";
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      panelClass: "custom_dialog_container",
      width: "440px",
      data: {
        title: status == "cancel" ? alertTitle : alertTitle2,
        message: status == "cancel" ? str : str2,
        type: "confirmDelete",
        buttonText: "Confirm",
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        this.restfullServices
          .updateSpaStatus(this.data.order.itemId, 'cancelled')
          .subscribe((response) => {
            if (response) {
              this.dialogRef.close({ data: new Array })
              this.openSnackBar('Appointment Cancelled!')
            }
          });
      });
  }
  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ["custom-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        svgIcon: "check-circle",
        message,
      },
    });
  }
}
