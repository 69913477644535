import { Subject } from 'rxjs';

export function AutoUnsubscribe() {
    return (constructor) => {
        const originalDestroy = constructor.prototype.ngOnDestroy;
        const destroy$: Subject<void> = new Subject();
        constructor.prototype['destroy$'] = destroy$;
        
        constructor.prototype.ngOnDestroy = function() {

            if (originalDestroy) {
                originalDestroy.call(this);
            }
            
            destroy$.next();
            destroy$.complete();
        };
        
        return constructor;
    };
}