<div class="orders-page width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">

	<form [formGroup]="ordersFilterForm" class="width_100" *ngIf="globalService.isMobile$.getValue()">
		<div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
			<div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
				<mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
					<mat-icon matPrefix>search</mat-icon>
					<input formControlName="search" matInput placeholder="Booking ref.,name, company">
					<button type="button" class="search_button" (click)="resetFilter()" *ngIf="searchValue">
						<i class="material-icons">close</i>
					</button>
				</mat-form-field>
			</div>
		</div>
	</form>
	<!-- <div fxLayout="column" fxLayoutAlign="start center" style="align-self: flex-start;">
        <span class="breadcrumb">All Orders</span>
    </div> -->

	<div class="width_100 position_relative" fxLayout="row" fxLayoutAlign="start center">
		<mat-tab-group fxFlex="100" (selectedIndexChange)="setReservationTable($event)"
			[selectedIndex]="activeTableIndex">
			<mat-tab *ngIf="globalService.HOTELID!=globalService.baobabHotelId">
				<ng-template mat-tab-label>
					<div fxLayoutAlign="start center" fxLayoutGap="5px">
						<div id="all_order">All Orders</div>
						<div *ngIf="globalService.foodAndBeveragesCount > 0" fxLayoutAlign="center center"
							class="request_count">
							{{localFoodAndBeveragesCount}}
						</div>
					</div>
				</ng-template>
				<div class="cleaning_detail_container">
					<div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">
						<form [formGroup]="ordersFilterForm" class="width_100 search-form"
							fxLayoutAlign="space-between center" *ngIf="!globalService.isMobile$.getValue()">
							<div class="filter_searchBox" fxFlex="25" fxLayout="row" fxLayoutAlign="start center">
								<div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
									<mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
										<mat-icon matPrefix>search</mat-icon>
										<input formControlName="search" matInput placeholder="Order No.">
										<button type="button" class="search_button" (click)="resetFilter()"
											*ngIf="searchValue">
											<i class="material-icons">close</i>
										</button>
									</mat-form-field>
								</div>
							</div>
							<div class="filter_Box" fxFlex="40" fxLayout="row" fxLayoutGap="20px"
								fxLayoutAlign="end center">
								<div (click)="resetFilter()" [class.clear_filter_section-hidden]="!isValueInFilterForm"
									class="clear_filter_section cursor_pointer" fxLayoutGap="5px" fxLayout="row"
									fxLayoutAlign="start center">
									<div>Clear filter</div>
									<mat-icon [svgIcon]="'refresh'"></mat-icon>
								</div>
								<div class="filter_section dropdownCustom" fxFlex="25" fxLayout="row"
									fxLayoutAlign="start center">
									<app-select placeholder="Sort by" [value]="getFormControl('sortBy').value"
										(selectionChange)="setFilterValue('sortBy', $event)"
										[selectOptions]="sortByOpt"></app-select>
								</div>
								<div class="filter_section dropdownCustom" fxFlex="25" fxLayout="row"
									fxLayoutAlign="start center">
									<app-select placeholder="Payment Status" [value]="getFormControl('payment').value"
										(selectionChange)="setFilterValue('payment', $event)"
										[selectOptions]="paymentStatusOpt"></app-select>
								</div>
								<div class="filter_section dropdownCustom" fxFlex="25" fxLayout="row"
									fxLayoutAlign="start center">
									<app-select placeholder="Order Status" [value]="getFormControl('status').value"
										(selectionChange)="setFilterValue('status', $event)"
										[selectOptions]="orderStatusOpt"></app-select>
								</div>
								<span id="add_order">
									<div class="button-section" (click)="addOrder()">
										<div fxLayoutAlign="center center">+ Add order</div>
									</div>
								</span>
							</div>

						</form>

						<div fxFlex="68" fxLayout="row" fxLayoutGap="15px" class="width_100" fxLayoutAlign="end center"
							*ngIf="globalService.isMobile$.getValue()">
							<div class="sort-section">
								<div fxLayoutAlign="center center" (click)="openSortFilter()">
									<mat-icon [svgIcon]="'settings'"></mat-icon>
									Sort / Filter
								</div>
							</div>
							<span>
								<div class="button-section" (click)="addOrder()">
									<div fxLayoutAlign="center center">+ Add order</div>
								</div>
							</span>
						</div>


						<div class="cleaning_body_container width_100" fxLayout="column" fxLayoutGap="30px"
							fxLayoutAlign="center center">
							<div class="request_list width_100">
								<mat-table #orderTable="matSort" [dataSource]="ordersDataSource" matSort
									class="width_100" matSortActive="orderNumber" matSortDirection="desc"
									matSortDisableClear>

									<ng-container matColumnDef="orderNumber">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Order No.
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.orderNumber}} </mat-cell>
									</ng-container>

									<ng-container matColumnDef="created">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Created
										</mat-header-cell>
										<mat-cell *matCellDef="let element" fxLayout="column"
											fxLayoutAlign="center start">
											{{formatDate(element.datePlaced)?.date}}
											<span>{{formatDate(element.datePlaced)?.time}}</span>
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="delivery">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Delivery Type
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{getDeliverType(element.delivery)}}</mat-cell>
									</ng-container>

									<ng-container matColumnDef="payment">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Payment Status
										</mat-header-cell>
										<mat-cell *matCellDef="let element"
											[class.disabled]="element.payment === 'cancelled'">
											<div class="payment_status" fxLayout="row" fxLayoutAlign="center center"
												[ngClass]="element.payment">
												<div>{{capitalizeFirstLetter(element.payment)}}</div>
											</div>
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="total">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Total
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.total | number:'1.2-2'}}
											{{globalService.currency}}</mat-cell>
									</ng-container>

									<ng-container matColumnDef="status">
										<mat-header-cell *matHeaderCellDef>
											Order Status
										</mat-header-cell>
										<mat-cell *matCellDef="let element">
											<div class="order_status" fxLayout="row" fxLayoutAlign="center center"
												[ngClass]="element.status.trim()">
												<div
													*ngIf="element.status != 'outfordelivery' && element.status != 'readyforpickup' && element?.status !='pickedup'">
													{{element.status}}</div>
												<div *ngIf="element.status === 'outfordelivery'">Out for delivery</div>
												<div *ngIf="element.status === 'readyforpickup'">Ready for pick-up</div>
												<div *ngIf="element.status === 'pickedup'">Picked-up</div>
											</div>
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="action">
										<mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">
											Action
										</mat-header-cell>
										<mat-cell *matCellDef="let element" (click)="$event.stopPropagation()"
											fxLayoutAlign="center center">
											<button mat-button fxLayoutAlign="center center" [matMenuTriggerFor]="menu"
												class="send_link_button">
												<mat-icon>more_horiz</mat-icon>
											</button>
											<mat-menu #menu="matMenu" yPosition="below">
												<button mat-menu-item (click)="addPayment(element)"
													[disabled]="element.payment == 'cancelled' || element.payment == 'paid'">
													Add Payment
												</button>
											</mat-menu>
										</mat-cell>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="ordersDisplayedColumns"></tr>
									<tr mat-row *matRowDef="let row; columns: ordersDisplayedColumns;"
										(click)="openOrderDetails(row.orderNumber)"
										[ngClass]="{'highlightRow': row?.status === 'pending' || row?.status ==='processing'}">
									</tr>
								</mat-table>
								<mat-paginator #ordersDataPaginator  (page)="handlePageEvent($event)" [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="pageLength" showFirstLastButtons></mat-paginator>
							</div>
						</div>

					</div>
				</div>
			</mat-tab>

			<mat-tab>
				<ng-template mat-tab-label>
					<div fxLayoutAlign="start center" fxLayoutGap="5px" id="table_reservation">
						<div>Reservation </div>
						<div *ngIf="localTableReservationCount > 0" fxLayoutAlign="center center"
							class="request_count">
							{{localTableReservationCount}}
						</div>
					</div>
				</ng-template>

				<div class="cleaning_detail_container">
					<div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">

						<form [formGroup]="reservationForm" class="width_100 search-form"
							fxLayoutAlign="space-between center" *ngIf="!globalService.isMobile$.getValue()">
							<div class="filter_searchBox" fxFlex="25" fxLayout="row" fxLayoutAlign="start center">
								<div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
									<mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
										<mat-icon matPrefix>search</mat-icon>
										<input formControlName="search" matInput
											placeholder="Reservation No., Guest Name" #input
											(keyup)="searchFilter($event.target.value)">
										<button type="button" class="search_button" (click)="resetReservation()"
											*ngIf="reservationSearch">
											<i class="material-icons">close</i>
										</button>
									</mat-form-field>
								</div>
							</div>
							<div class="filter_Box" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
								<div (click)="resetReservation()"
									[class.clear_filter_section-hidden]="!isReservationInForm"
									class="clear_filter_section cursor_pointer" fxLayoutGap="5px" fxLayout="row"
									fxLayoutAlign="start center">
									<div>Clear filter</div>
									<mat-icon [svgIcon]="'refresh'"></mat-icon>
								</div>

								<div class="filter_section sortSection dropdownCustom" fxLayout="row"
									fxLayoutAlign="start center">
									<mat-form-field class="custom_Select">
										<mat-select placeholder="Sort by" (selectionChange)="onCustomeSorting($event)"
											formControlName="sortingValue">
											<mat-option value="">Select</mat-option>
											<mat-option [value]="sort.value" *ngFor="let sort of sortingList;">
												{{sort.key}}
											</mat-option>
										</mat-select>
										<mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
									</mat-form-field>
								</div>
								<div class="filter_section dropdownCustom" fxFlex="25" fxLayout="row"
									fxLayoutAlign="start center">
									<app-select placeholder="Status" [value]="status.value"
										(selectionChange)="status.setValue($event)"
										[selectOptions]="reservationStatus"></app-select>
								</div>
								<span id="add_reservation">
									<div class="button-section" (click)="addReservation()">
										<div fxLayoutAlign="center center">+ Add Reservation</div>
									</div>
								</span>
							</div>
						</form>

						<div class="cleaning_body_container width_100" fxLayout="column" fxLayoutGap="30px"
							fxLayoutAlign="center center">
							<div class="request_list width_100">
								<mat-table #craneTable="matSort" [dataSource]="dataSource" matSort class="width_100"
									 matSortDirection="desc" matSortDisableClear>
									<ng-container matColumnDef="reservationId">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Reservation ID
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.reservationId}} </mat-cell>
									</ng-container>

									<ng-container matColumnDef="guestName">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Guest Name
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.guestName}} </mat-cell>
									</ng-container>

									<ng-container matColumnDef="people">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											People
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.people}} </mat-cell>
									</ng-container>

									<ng-container matColumnDef="date">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Reservation Date time
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.date | presentDateFormat}}, {{element.time}}
										</mat-cell>
									</ng-container>

									<!-- <ng-container matColumnDef="table">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Table
										</mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.table}} </mat-cell>
									</ng-container> -->

									<ng-container matColumnDef="status">
										<mat-header-cell *matHeaderCellDef mat-sort-header>
											Status
										</mat-header-cell>
										<mat-cell *matCellDef="let element">
											<div class="reservation_status" fxLayout="row" fxLayoutAlign="start center"
												[ngClass]="element.status | specialCharPipe | removeWhiteSpace">
												<div style=" margin:0 auto">{{element.status}}</div>
											</div>
										</mat-cell>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="reservationDisplayColumn"></tr>
									<tr mat-row *matRowDef="let row; columns: reservationDisplayColumn;"
										(click)="openReservationDetails(row)">
									</tr>
								</mat-table>



								<mat-paginator #reservationDataPaginator [pageSize]="10"
									[pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
								</mat-paginator>
							</div>
						</div>

					</div>
				</div>

			</mat-tab>
		</mat-tab-group>
	</div>

</div>